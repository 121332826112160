import { utils } from "../utils";

const debounce = utils.debounce;
export const ListPageMixin = {
	props: {
		paginate: {
			type: Boolean,
			default: true,
		},
		showHeader: {
			type: Boolean,
			default: true,
		},
		showFooter: {
			type: Boolean,
			default: true,
		},
		showBreadcrumbs: {
			type: Boolean,
			default: true,
		},
		exportButton: {
			type: Boolean,
			default: true,
		},
		importButton: {
			type: Boolean,
			default: false,
		},
		listSequence: {
			type: Boolean,
			default: false,
		},
		multiCheckbox: {
			type: Boolean,
			default: true,
		},
		emptyRecordMsg: {
			type: String,
			default: "No record found",
		},
		msgBeforeDelete: {
			type: String,
			default: "Are you sure you want to delete this record?",
		},
		msgAfterDelete: {
			type: String,
			default: "Record deleted successfully",
		},
		page: {
			type: Number,
			default: 1,
		},
		limit: {
			type: Number,
			default: 20,
		},
		search: {
			type: String,
			default: '',
		},
		fieldName: null,
		fieldValue: null,
		sortBy: {
			type: String,
			default: '',
		},
		sortType: {
			type: String,
			default: '', //desc or asc
		},
		exportFormats: {
			type: Array,
			default: function () { return ['print', 'pdf', 'csv', 'excel'] },
		},
	},
	data: function () {
		return {
			totalRecords: 0,
			recordCount: 0,
			loading: false,
			ready: false,
			singleSelect: false,
			selectedItems: [],
			pagination: {
				page: 1,
				rowsPerPage: 20,
				sortBy: '',
				rowsNumber: 10,
				descending: true,
			},
			deleting: false,
			includeFilters: true,
			searchText: '', //debounce input value
			searchInput: '',
			errorMsg: ''
		}
	},
	computed: {
		apiUrl: function () {
			let path = this.apiPath;
			if (this.fieldName) {
				path = path + '/' + encodeURIComponent(this.fieldName) + '/' + encodeURIComponent(this.fieldValue);
			}
			let route = this.$route.query;


			if (this.sortBy) {
				this.pagination.sortBy = this.sortBy;
			}
			else if (route.sortby) {
				this.pagination.sortBy = route.sortby;
			}

			if (this.sortType) {
				this.pagination.descending = (this.sortType.toLowerCase() == 'desc');
			}
			else if (route.sorttype) {
				this.pagination.descending = (route.sorttype.toLowerCase() == 'desc');
			}

			if (route.search) {
				this.searchText = route.search;
			}
			let orderType = this.pagination.descending ? 'desc' : 'asc';
			let query = {
				page: this.pagination.page,
				limit: this.pagination.rowsPerPage,
				
			};

			if(this.pagination.sortBy){
				query.orderby = this.pagination.sortBy;
				query.ordertype = orderType;
			}
			
			if (this.searchText) {
				query.search = this.searchText;
			}
			let filters = this.filters;
			for (var key in filters) {
				if (filters[key] && filters[key].toString() != '') {
					query[key] = filters[key]
				}
			}
			
			const queryParams = this.$utils.serializeQuery(query);
			return path + "?" + queryParams;
		},
		recordsPosition: function () {
			return Math.min(this.pagination.page * this.pagination.rowsPerPage, this.totalRecords);
		},
		totalPages: function () {
			if (this.totalRecords > this.pagination.rowsPerPage) {
				return Math.ceil(this.totalRecords / this.pagination.rowsPerPage);
			}
			return 1;
		},

		finishedLoading: function () {
			if (this.recordCount < this.pagination.rowsPerPage && this.records.length) {
				return true;
			}
			return false;
		},
		canLoadMore: function () {
			if (this.loading || this.finishedLoading) {
				return false;
			}
			return true;
		},
		pageBreadCrumb: function() {
			let page = this.pageName;
			let items = [];
			
			let filterName = this.$route.query.tag || this.fieldName;
			items.push({
				label: filterName,
				to: `/${page}`
			});

			let filterValue = this.$route.query.label || this.fieldValue;
			items.push({
				label: filterValue,
				to: `/${page}`
			});
			return items;
		},
	},
	methods: {
		
		onSort(event) {
			if(this.mergeRecords){
				this.records = [];
				this.pagination.page = 1
			}
			this.pagination.sortBy = event.sortField;
			this.pagination.descending = event.sortOrder==-1;
        },
		reload: function () {
			this.records = []
			let query = this.$route.query;
			if (query.limit) {
				this.limit = query.limit;
			}
			if (query.page) {
				this.page = query.page;
			}
			if (query.sortby) {
				this.pagination.sortBy = query.sortby;
			}
			if (query.sorttype) {
				this.pagination.descending = (query.sorttype == 'desc');
			}
			if (query.search) {
				this.searchText = query.search;
			}
			this.pagination.rowsPerPage = this.limit;
			this.pagination.page = this.page;
			this.load();
		},
		setCurrentRecord: function (record) {
			this.currentRecord = record;
		},
		isCurrentRecord: function (row) {
			return row == this.currentRecord;
		},
		doSearch: function () {
			this.includeFilters = false;
		},
		deleteItem: function (id) {
			if (Array.isArray(id)) {
				id = id.map(value => value[this.primaryKey]);
			}
			if (id) {
				let title = "Delete record";
				let prompt = this.msgBeforeDelete;

				this.$confirm.require({
					message: prompt,
					header: title,
					icon: 'pi pi-exclamation-triangle',
					accept: () => {
						//callback to execute when user confirms the action
						var url = this.pageName + '/delete/' + id.toString()
						var data = { id, url };
						this.deleteRecord(data).then(
							(response) => {
								this.flashMsg(this.msgAfterDelete);
							},
							(response) => {
								this.showPageRequestError(response);
							});
					},
					reject: () => {
						//callback to execute when user rejects the action
					}
				});
			}
		},
		clearSearch(){
			this.searchInput = '';
			this.searchText = '';
			this.$route.query.search = '';
		},
		importComplete: function (message) {
			this.flashMsg(message);
			this.reload();
		},
	},
	watch: {
		searchText: function () {
			if(this.searchText){
				this.pagination.page = 1;
			}
		},
		searchInput: debounce(function (newVal) {
			this.searchText = newVal
		}, 500),
		filters: {
			handler(val){
				this.pagination.page = 1;
			},
			deep: true
		}
	},
	mounted: function () {
		this.showError = false;
		this.singleSelect = !this.multiCheckbox;
		this.reload();
	},
	created: function () {
		// this.$on('RefreshPage', () => {
		// 	this.load();
		// });
	},
}